@import '../../conststants';

.contractors {
  margin-top: 2rem;
  @include stacked-table(550px);
  & table.ui.table tr {
    & th.limited-width {
      width: 25%;
    }
    & td.limited-width {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 1px;
      @media screen and (max-width: 550px) {
        max-width: 100%;
      }
    }
  }
  &.mobile {
    @include mobile-management-layout();
  }
}
