@import '../../conststants';

.list-panel-history {
  margin: 0 1em;
  @include stacked-table(900px);

  table.ui.table thead tr th {
    text-align: center;
  }
  table.ui.table tbody tr td {
    text-align: center;
  }
  table.ui.table tbody tr td:last-child {
    display: block;
    text-align: center;
  }

  &.mobile {
    @include stacked-table(10000px);

    table.ui.table tbody tr td {
      text-align: right;
    }

    table.ui.table tbody tr td:last-child {
      text-align: right;
    }
  }
}

@media (max-width: 900px) {
  .list-panel-history {
    table.ui.table tbody tr td {
      text-align: right;
    }

    table.ui.table tbody tr td:last-child {
      text-align: right;
    }
  }
}


@media (max-width: 480px) {
  .list-panel-history {

    table.ui.table thead tr th {
      font-size: 12px;
    }

    table.ui.table tbody tr td {
      font-size: 11px;
    }

  }
}
