@import '../conststants';

.not-found {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1, h2 {
    color: $base-color;
  }
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 3em;
  }
}
