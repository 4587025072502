@import '../../conststants';

.reports-total-panel {
  margin: 0 1em 0;
  & div.ui.segment {
    width: 100%;
    & .reports-statistic-wrapper {
      display: grid;
      grid-template-columns: 1fr 1.25fr 1.25fr 1.25fr 1.25fr auto;
      & div {
        padding: 4px;
        min-width: 110px;
        & span {
          font-size: 15px;
          border-bottom: 1px solid $base-color-dark;
        }
      }
    }
  }
  &.mobile {
    div.ui.segment .reports-statistic-wrapper div span {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}
