.profile {
  & h2 {
    text-align: center;
  }
  &.mobile {
    margin-top: 3rem;
    h2 {
      font-size: 5rem;
      margin-bottom: 4rem;
    }
    form {
      font-size: 3rem;
      line-height: 3rem;
      .button-wrapper {
        display: flex;
        justify-content: space-between;
        button {
          font-size: 2.5rem;
        }
      }
    }
  }
}
