.notification-container {
  position: fixed;
  right: 2%;
  top: 8%;
  bottom: 10%;
  z-index: 9999;
  .ui.message {
    padding-right: 28px;
  }
  .ui.message .content ,.icon {
    color: white;
  }

  .ui.red.message{
    background-color: #CA3B33;
  }


}
