@import '../../../conststants';

#invoicePDF {
  display: flex;
  flex-direction: column;
  width: 595px;
  min-height: 842px;
  padding: 15mm 10mm;
  & .row-1 {
    display: flex;
    width: 100%;
    flex-direction: row;
    & .logo {
      width: 300px;
      flex: 1;
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    & .date-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      & .invoice-date {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4mm;
        & .date-label {
          color: $base-color-dark;
          font-weight: 700;
        }
        & .date {
          margin-left: 5px;
          width: 25mm;
        }
      }
    }
  }
  & .row-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16mm;
    & h3 {
      margin: 0;
    }
  }
  & .row-3, .row-4, .row-5, .row-7, .row-8, .row-9 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10mm;
    font-weight: 700;
    & .invoice-label {
      color: $base-color-dark;
      font-size: 4.5mm;
    }
    & .invoice-data {
      margin-left: 3mm;
      margin-top: 0.6mm;
    }
  }
  & .row-3, .row-4 {
    & .left-part {
      display: flex;
      flex: 1;
    }
    & .right-part {
      display: flex;
      flex: 1;
    }
  }
  & .row-4 {
    margin-top: 2mm;
    & .left-part, & .right-part {
      flex-direction: column;
    }
  }
  & .row-7, .row-8, .row-9 {
    & .invoice-label {
      color: #000000;
      font-size: 4mm;
    }
    & .invoice-data {
      margin-top: 0;
    }
  }
  & .row-8, .row-9 {
    margin-top: 3mm;
  }
  & .row-5, .row-6, .row-7 {
    margin-top: 8mm;
  }
  & .row-6 {
    & table {
      border: 1px solid #c5c1c1;
      border-collapse: collapse;
      width: 100%;
      & thead {
        background-color: $base-color-ultralight;
        & tr {
          & th {
            text-align: center;
            &:nth-child(1), &:nth-child(3), &:nth-child(4) {
              width: 7mm;
            }
            &:nth-child(3) {
              width: 15mm;
            }
            &:nth-child(4) {
              width: 34mm;
            }
          }
        }
      }
      & tbody {
        & tr {
          border-bottom: 1px dotted #cccccc;
          & td {
            &.text-centered {
              text-align: center;
            }
          }
        }
      }
    }
  }

  & .row-9 {
    align-items: flex-start;
  }
}
