@import '../conststants';

body .modals .management-modal.mobile {
  font-size: 2rem;
  form {
    font-size: 2rem;
    div.menu .item {
      font-size: 2rem;
    }
  }
}

.management {
  width: 100%;
  & h5.icon i {
    color: $base-color;
  }
  & div.container div.column {
    justify-content: center;
    h5, h5.icon i {
      cursor: pointer;
      transition: all 1s;
      &:hover, &.isActive-true {
        color: $base-color;
        &.icon i {
          color: $base-color-dark;
        }
      }
    }
  }
  & .management-router-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    .button-wrapper {
      width: 100%;
      margin-bottom: 30px;
      button {
        font-size: 2rem;
      }
    }
  }
  &.mobile {
    &:not(.root-menu) {
      div.ui.text.container {
        display: none;
      }
    }
    &.root-menu .management-router-wrapper {
      display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    div.ui.text.container {
      width: 100%;
      margin-right: 5rem!important;
      margin-left: 5rem!important;
      & > div.ui.grid {
        flex-wrap: wrap;
        div.column {
          width: 50%;
          display: flex;
          justify-content: center;
          padding-top: 30px;
          padding-bottom: 30px;
          box-shadow: 0 4px 2px -2px $base-color;
          &:nth-child(even) {
            box-shadow: -4px 4px 4px -2px $base-color;
          }
          & > h5 {
            font-size: 3rem;
            i {
              font-size: 10rem;
            }
          }
        }
      }
    }
  }
}
