@import '../conststants';

body {
  #root, div.app, div.login-form, div.login-form > div {
    height: 100%;
  }
  div.login-form {
    & > div > div.column {
      max-width: 450px;
      & > h2 {
        color: $base-color;
      }
      & > form .ui.stacked.segment {
        button {
          background: $base-color;
          color: #ffffff;
          opacity: 0.8;
          transition: opacity 1s;
          &:hover {
            opacity: 1;
          }
        }
        &:after {
          display: none;
        }
      }
    }
    &.mobile {
      & > div > div.column {
        min-width: calc(100vw - 60px);
        h2, input, i, span, button {
          font-size: 4rem;
        }
        .header {
          display: block;
          & img, span {
            display: block;
            margin: 0 auto;
          }
          & img {
            width: calc(100% - 60px);
            max-width: 500px;
            margin-bottom: 100px;
          }
          & span {
            width: 100%;
          }
        }
        button {
          margin-top: 100px;
        }
        .ui.error.message {
          font-size: 3rem;
        }
      }
    }
  }
}
