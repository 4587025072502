.ui.container.main {
  display: flex;
  flex-direction: column;
  width: 100%!important;
  margin-left: 5rem!important;
  margin-right: 5rem!important;
  .vertically.grid {
    justify-content: center;
  }

  &.mobile {
    width: 100%;
    margin-left: 3rem!important;
    margin-right: 3rem!important;
  }
}
