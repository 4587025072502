@import '../../conststants';

.invoice {
  margin-bottom: 60px;
  .error {
    display: flex !important;
  }
  & #the-invoice-form {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  & .paid-button-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & .add-service-info-button,
    & .paid-button {
      margin-bottom: 8px;
      margin-right: 10px;
    }
    & .paid-input {
      width: 100%;
      margin-bottom: 8px;
      & input {
        flex: 1;
        text-align: right;
      }
    }
    .paid_status {
      &.orange {
        .selection {
          background-color: $base-color;
        }
      }
      &.green {
        .selection {
          background-color: #0ea432;
        }
      }
      &.red {
        .selection {
          background-color: #db2828;
        }
      }
    }
  }

  & .service-info__comments-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  & .service-info__comments-buttons-space-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  & .service-info-comment__height-button {
    align-self: flex-end;
  }
  & .extension-field {
    min-width: 170px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
      -moz-appearance: textfield;
  }




  & .control-buttons-wrapper {
    text-align: right;
  }
  & .service-info {
    & .header {
      display: flex;
      justify-content: space-between;
    }
  }
  &.mobile {
    min-width: 96%;
    margin-left: 20px!important;
    margin-right: 20px!important;
    form#the-invoice-form {
      h2 {
        font-size: 5rem;
      }
      h3 {
        font-size: 4rem;
      }
      div.field {
        font-size: 2rem;
        line-height: 2rem;

        div.dropdown {
          font-size: 2rem;
          line-height: 2rem;

          i.icon {
            margin-top: -34px;
            font-size: 2rem;
          }

          .menu .item {
            font-size: 3rem!important;
          }
        }
      }
      div.paid-button-wrapper {
        div.paid-input {
          font-size: 2.3rem;
          line-height: 2.3rem;
        }
      }
    }
    button.button {
      font-size: 2.5rem;
    }
  }
}

.ui.modal.invoice-preview-modal {
  .content.invoice-modal-content {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0!important;
  }
  &.mobile {
    transform: scale(1.45);
  }
}
