@import '../../conststants';
$stacked-width: 950px;

.reports-list-panel {
  margin: 0 1em;
  @include stacked-table($stacked-width);

  & table.ui.table {
    & thead tr th:last-child {
      text-align: center;
    }
    & tbody tr td:last-child {
      display: block;
      @media screen and (min-width: $stacked-width) {
        text-align: center;
      }
    }
  }
  &.mobile {
    @include stacked-table(10000px);
    button.ui.button, .search {
      font-size: 2rem;
    }
    table.ui.table tbody tr {
      margin-bottom: 16px;
      td {
        font-size: 2rem;
        min-height: 45px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}
