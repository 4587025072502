@import "../../conststants";

.top-navbar {
  &.ui.fixed.menu {
    background: #ffffff;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.14);
    .item:not(.header) {
      background: #ffffff;
      transition: all 1s;
      a.navigation-button {
        color: #000000;
        span.description {
          margin-left: 10px;
          font-size: 1.3em;
        }
        i.icon {
          display: none;
        }
      }
      a.navigation-button.active {
        & span.description, & i.icon {
          color: $base-color;
        }
      }
      &:hover {
        cursor: pointer;
        a.navigation-button:not(.active) {
          & span.description,
          & i.icon {
            text-shadow: 2px 2px 4px $base-color-light;
          }
        }
      }
    }
    .header.item {
      & img {
        margin-right: 1.5em;
      }
      & span.logo-description {
        color: #000000;
        font-size: 1.45em;
        font-weight: 800;
        letter-spacing: 3px;
      }
    }
    .ui.divider {
      flex: 1;
    }
    .item.dropdown {
      .text, i {
        color: #000000;
        font-size: 1.3em;
      }
      .menu {
        display: none;
        &.visible {
          display: flex;
        }
      }
    }

    &.mobile {
      height: 10rem;
      .ui.container {
        margin: 0!important;
        width: 100%;
        align-items: center;
        .header {
          display: none;
        }
        .item:not(.header) {
          padding: 0 8px;
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            &:before {
              content : "";
              position: absolute;
              left: 0;
              top: 1rem;
              height: 75%;
              border-left: 5px solid $base-color-light;
            }
          }
          a.navigation-button {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
            &:not(.active) {
              text-shadow: 1px 1px 2px black, 0 0 25px $base-color, 0 0 5px $base-color-light;
            }
            &.active {
              text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black;
            }
            i.icon {
              display: block;
              font-size: 5em;
              margin: 0 2.5rem;
            }
            span.description {
              display: none;
            }
          }
          &.dropdown {
            i.icon {
              font-size: 5rem;
              text-shadow: 1px 1px 2px black, 0 0 25px $base-color, 0 0 5px $base-color-light;
            }
            .text {
              display: none;
            }
            .menu .item {
              font-size: 4.5rem!important;
            }
          }
        }
      }
    }
  }
}

