@import '../../conststants';

div.row.filters-panel {
  max-width: 1280px;
  & div.segment.filters-panel-wrapper {
    width: 100%;
    & div.column.first-column,
      div.column.second-column,
      div.column.third-column,
      div.column.fourth-column {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      & div.row {
        padding: 5px;
        width: 100%;
        align-items: center;
        & div.ui.dropdown {
          border-color: $base-color;
          & a {
            background-color: $base-color-ultralight;
          }
        }
      }
      & .date-picker-wrapper {
        display: flex;
        justify-content: center;
        @extend %date-range-picker;
      }
      & .inline-filters {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        &.last-row {
          & > .dropdawn:last-child {

          }
        }
        & >.dropdown {
          margin-left: 1px;
          margin-right: 1px;
        }
      }
    }
  }
  &.mobile div.segment.filters-panel-wrapper {
    & > div.column {
      flex-direction: column;
      div.row.inline-filters {
        flex-direction: column;
      }
      input, div.dropdown {
        font-size: 1.8rem;
      }
      div.default.text {
        font-size: 1.8rem;
        margin-top: 14px;
      }
      div.dropdown {
        i.icon {
          margin-top: -34px;
          font-size: 2rem;
        }
        &:not(.multiple) {
          margin-top: 10px;
          padding-top: 6px;
          padding-bottom: 6px;
          padding-left: 23px;
        }
        .menu .item {
          font-size: 3rem!important;
        }
        a.ui.label {
          font-size: 2.1rem;
        }
      }
      .DateRangePicker {
        width: 100%;
        div.DateRangePickerInput {
          width: 100%;
          display: flex;
          div.DateInput {
            flex-grow: 1;
            padding-bottom: 5px;
            padding-top: 5px;
            input.DateInput_input {
              text-align: center;
              font-size: 3rem;
              line-height: 3rem;
              padding-bottom: 0!important;
              padding-top: 0!important;
            }
          }
          .DateRangePickerInput_arrow {
            display: flex;
            align-items: center;
            i {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  div.row.filters-panel {
    & div.segment.filters-panel-wrapper {
      & div.column.first-column, div.column.third-column {
        flex-direction: column;
      }
      & div.column.fourth-column {
        flex-direction: column;
      }
    }
  }
}
