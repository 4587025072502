@import '../../conststants';

.list-panel {
  margin: 0 1em;
  @include stacked-table(991px);
  table.ui.table tbody tr td {
    &:nth-last-child(3) {
      text-align: center;
      @media screen and (max-width: 991px) {
        text-align: right;
      }
      .info {
        cursor: help;
      }
    }
    &:last-child {
      justify-content: flex-start;
    }
    .tech-divider {
      display: block;
      min-height: 19px;
    }
  }
  &.mobile {
    @include stacked-table(10000px);
    button.ui.button, .search {
      font-size: 2rem;
    }
    table.ui.table tbody tr {
      margin-bottom: 16px;
      td {
        font-size: 2rem;
        min-height: 45px;
        padding-top: 12px;
        padding-bottom: 12px;
        &:last-child {
          button:first-child {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
