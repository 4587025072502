$base-color: #f09414;
$base-color-dark: #f26202;
$base-color-light: rgba(240,148,20,0.5);
$base-color-ultralight: rgba(240,148,20,0.15);

%tables {
  table.ui.table {
    & tr th {
      cursor: pointer;
      color: black;
      text-align: left;
      transition: color 0.5s;
      &:hover {
        color: $base-color;
      }
    }
    & tbody {
      & tr td {
        text-align: left;
        &:last-child {
          text-align: right;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }
      & button.ui.icon {
        background: $base-color;
        transition: background-color 0.5s;
        &:hover {
          background: $base-color-dark;
        }
        & i {
          color: white;
        }
      }
    }
    & div.ui.orange.buttons button {
      background-color: $base-color;
      transition: all 1s;
    }

    tr {
      border: 1px solid #ddd;
      padding: 5px;
    }

    th, td {
      padding: 10px;
      text-align: center;
    }

    th {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}

%mobile-management {
  div.ui.container {
    width: 100%;
  }
  button.ui.button, .search {
    font-size: 2rem;
  }
  table.ui.table {
    thead tr th {
      font-size: 2rem;
      line-height: 2.2rem;
    }
    tbody tr td {
      font-size: 2rem;
      line-height: 2.2rem;
      min-height: 45px;
      padding-top: 12px;
      padding-bottom: 12px;
      &:last-child {
        button:first-child {
          margin-right: 40px;
        }
      }
    }
  }
}
@mixin mobile-management-layout() {
  @extend %mobile-management;
}

@mixin stacked-table($max-width) {
  @extend %tables;
  @media screen and (max-width: $max-width) {
    table.ui.table {
      border: 0;
      & thead tr {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border: none;
        &:last-child {
          display: none;
        }
        & th {
          background: inherit;
          border: none;
          &:last-child {
            width: 100%;
            & div.ui.icon.input {
              width: 100%;
            }
          }
        }
      }
      & tbody {
        & tr {
          margin-bottom: 10px;
          display: block;
          border-top: 2px solid $base-color;
          border-bottom: 2px solid $base-color;
          border-radius: 5px;
          box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
          & td {
            display: block;
            text-align: right;
            font-size: 13px;
            border-bottom: 1px dotted #ccc;
            &:last-child {
              border-bottom: 0;
            }
            &:before {
              content: attr(data-label);
              float: left;
              text-transform: uppercase;
              font-weight: bold;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

%date-range-picker {
  .DateRangePicker {
    & .DateRangePickerInput {
      border-color: $base-color;
      border-radius: 3px;
      & .DateInput input.DateInput_input {
        padding: 5px 12px 5px;
      }
      & .DateInput_input__focused {
        border-bottom-color: $base-color-dark;
      }
    }
    .CalendarMonthGrid .CalendarMonth_table {
      & .CalendarDay__selected_start, .CalendarDay__selected_end {
        background-color: $base-color-dark;
        border-color: $base-color-dark;
      }
      & .CalendarDay__selected_span {
        background-color: $base-color;
        border-color: $base-color-dark;
      }
      & .CalendarDay__hovered_span {
        background-color: $base-color-light;
        border-color: $base-color-light;
      }
    }
    & .DayPickerKeyboardShortcuts_show {
      border-right-color: $base-color-dark;
    }
  }
}
